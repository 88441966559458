import React from "react";
import Header from "../../Component/Common/Header";

function PasswordReset() {
  return (
    <div className="bg-black w-full min-h-screen">
      <Header />
      <div className="flex flex-col items-center mt-16">
        <div className=" w-[500px] text-white/70">
          <div className="text-white text-xl">Password reset</div>
          <div className="mt-7 mb-2">New password</div>
          <div className="border border-white/40 h-12">
            <input
              type="text"
              className="h-full outline-none w-full bg-transparent rounded-sm"
            />
          </div>
          <div className="mt-4 mb-2">Comfirm password</div>
          <div className="border border-white/40 h-12">
            <input
              type="text"
              className="h-full outline-none w-full bg-transparent rounded-sm"
            />
          </div>
          <div className="mt-4 w-full bg-white h-12 rounded-md text-black flex items-center justify-center">Reset password</div>
        </div>
      </div>
    </div>
  );
}

export default PasswordReset;
