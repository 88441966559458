import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Account from "./Pages/Account/Account";
import Contact from "./Pages/Contact/Contact";
import Privacy from "./Pages/Policy/Privacy";
import About from "./Pages/Contact/About";
import BLogs from "./Pages/Blog/Blogs";
import BlogDetail from "./Pages/Blog/BlogDetail";
import PasswordReset from "./Pages/Account/PasswordReset";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/user/deleterequest/:id" element={<Account />} />
        <Route path="/in/privacy" element={<Privacy />} />
        <Route path="/in/about" element={<About />} />
        <Route path="/in/passwordreset" element={<PasswordReset />} />
        <Route path="/blog/:title" element={<BlogDetail />} />
        <Route path="/in/blogs" element={<BLogs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
