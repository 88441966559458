import React, { useEffect, useState } from "react";

import axios from "axios";
import { CiSearch } from "react-icons/ci";
import Header from "../../Component/Common/Header";
import { Link } from "react-router-dom";
import Footer from "../../Component/Common/Footer";

function Home() {
  const [campagin, setCampagin] = useState<any>([]);
  const _getCampagin = async () => {
    try {
      const request: any = await axios.get(
        "https://service.ruffelo.com/campagin/list"
      );
      console.log(request.data.data);
      setCampagin(request.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    _getCampagin();
  }, []);
  return (
    <div className="bg-[black]">
      <Header />
      <div className=" w-full flex py-8 pb-16 px-28 max-[760px]:px-6 max-sm:px-0">
        <div className="w-8/12 relative bg-black max-[1133px]:w-full">
          <div className="absolute bg-[#fff3cd] w-full h-full top-2 -rotate-3 -z-0"></div>
          <div className="bg-black z-10 relative w-full py-20 px-20 top-0 border border-white/10 max-md:px-6">
            <div className="text-6xl text-white font-light max-[720px]:text-6xl max-[1140px]:w-full max-sm:text-4xl ">
              Welcome to <span className="text-[#FFC300]">Ruffelo</span>
            </div>
            <div className="text-xl text-white w-full mt-3 max-[720px]:text-xl max-[720px]:w-full max-sm:text-center">
              Your on-the-go financial partner. Download free apps, complete
              tasks, and turn your phone into a cash machine!
            </div>
            <div className="mb-6 mt-10 flex items-center w-">
              <input
                placeholder="Enter your email address"
                className="w-full h-12 bg-transparent rounded-lg border border-white/60 px-4 text-white outline-none"
              />
              <div className="bg-[#FFC300] ml-3 w-40 h-12 flex items-center justify-center rounded-lg">
                Get app
              </div>
            </div>
            <div className="mt-8 mx-3 flex items-start justify-between flex-wrap">
              <div className=" mr-12">
                <div className="text-white text-2xl font-medium">
                  3 million +{" "}
                </div>
                <div className="text-white/40 text-sm">Downloads</div>
              </div>
              <div className=" mr-12">
                <div className="text-white text-2xl font-medium">400+</div>
                <div className="text-white/40">Active users</div>
              </div>
              <div className="mr-12">
                <div className="text-white text-2xl font-medium mr-9">
                  3 million +{" "}
                </div>
                <div className="text-white/40">Downloads</div>
              </div>
            </div>
            <div className="flex items-center mt-10 max-md:hidden">
              {campagin.map((campagin: any, index: number) => (
                <div className="w-14 h-14 -mr-2">
                  <img
                    className="w-full h-full bg-[#FFC300] object-contain rounded-xl"
                    src={campagin.campaign_image}
                    alt="apps"
                  />
                </div>
              ))}
            </div>
            <span className="text-white/60 max-md:hidden">
              100+ more apps to install
            </span>
          </div>
        </div>
        <div className="w-4/12 flex items-center justify-end max-[1133px]:hidden">
          <div className="h-full w-[330px] border-[6px] rounded-lg border-white/70 overflow-hidden">
            <div className="py-5 px-7 w-full bg-black ">
              <div className="flex items-center justify-between">
                <div className="text-white font-semibold">Dashboard</div>
                <div>
                  <div>
                    <CiSearch color="white" size={23} />
                  </div>
                </div>
              </div>
              <div className="pt-3">
                {campagin.map((campagin: any, index: number) => (
                  <div className="py-3 flex items-center">
                    <div className="w-14 h-14 mr-3">
                      <img
                        className="w-full h-full bg-[#FFC300] object-contain rounded-xl"
                        src={campagin.campaign_image}
                        alt="apps"
                      />
                    </div>
                    <div>
                      <div className="text-white text-sm">
                        {campagin.campaign_name}{" "}
                      </div>
                      <div className="text-white/60 text-xs">
                        {campagin.campaign_category}{" "}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
